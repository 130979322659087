import jwt from 'jwt-decode'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const axios = require('axios')

const axios_instance = axios.create({
  baseURL: process.env.GATSBY_API_URL
})

const isBrowser = () => typeof window !== "undefined"

const getUser = () =>
  isBrowser() && window.localStorage.getItem("currentUser")
    ? JSON.parse(window.localStorage.getItem("currentUser"))
    : {}


function refresh (failedRequest) {
  const user = getUser()
  if(user.refresh){
    return axios_instance.post('/auth/token/refresh', { refresh: user.refresh})
      .then(data => {
        var user = data.data
        var user_token = jwt(user.access)
        user_token.access = user.access
        user_token.refresh = user.refresh
        localStorage.setItem('currentUser', JSON.stringify(user_token))
        failedRequest.response.config.headers.Authorization = 'Bearer ' + user.token
        return Promise.resolve()
      },
      (error) => {
        return Promise.reject(error.response)
      }
    )
  }
}

const verify = (token) => axios_instance.post('/auth/token/verify', {token:token}).then( response =>  response.data).catch(error => console.log(error))


axios_instance.interceptors.request.use(
  config => {

    const user = getUser()
    config.headers.Authorization = 'Bearer ' + user.access
    return config
  },
  error => {
    Promise.reject(error)
  }
)

const refreshAuthLogic = failedRequest => refresh(failedRequest)
createAuthRefreshInterceptor(axios_instance, refreshAuthLogic)


export const isLoggedIn =  async () => {
  const user = getUser()
  if(user.access) {
    const token = user.access
    return await verify(token).then(data => true
    ).catch(error => {
      console.log(error)
      return false
    })
  }
}
