/* eslint-disable */
import React, { useState, useEffect} from 'react';
import { isLoggedIn } from '../src/services/auth'
const authenticate = process.env.GATSBY_AUTH

const AuthenticatedApp = ({element}) => {
  const [authenticated, setAuthenticated] = useState()
  useEffect(() => {
    if(authenticate === 'true'){
      isLoggedIn().then(validToken => {
        setAuthenticated(validToken)
        if(!validToken) {
          window.location.replace(`${process.env.GATSBY_APP_URL}/?redirect=${window.location.pathname}`)
        }
      }).catch(error => {
        console.log(error)
      })
    } else {
      setAuthenticated(true)
    }
  }, [authenticated])
  return (
    <>
      {authenticated && element}
    </>
  )
}

export const wrapRootElement = ({ element }) => {
  return <AuthenticatedApp element={element} />
}
